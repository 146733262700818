import { onMounted, computed } from "vue";
import { templateRef } from "@vueuse/core";
import useTailwindCss from "/composables/useTailwindCss";


export default {
  __name: 'index',
  props: {
  src: {
    type: [String, Object],
    required: true,
  },
  alt: {
    type: String,
    required: false,
  },
  longdesc: {
    type: String,
    required: false,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  decoding: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "sync", "async"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  width: {
    type: Number,
    required: false,
    validator: function (value) {
      return value > 0;
    },
  },
  height: {
    type: Number,
    required: false,
    validator: function (value) {
      return value > 0;
    },
  },
  imgClass: {
    type: String,
    required: false,
  },
  referrerpolicy: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "no-referrer",
          "no-referrer-when-downgrade",
          "origin",
          "origin-when-cross-origin",
          "unsafe-url",
        ].indexOf(value) !== -1
      );
    },
  },
  crossorigin: {
    type: String,
    required: false,
    validator: function (value) {
      return ["anonymous", "use-credentials"].indexOf(value) !== -1;
    },
  },
  draggable: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props



const { screens } = useTailwindCss();
const ref_img = templateRef("ref_img");
const screensOrder = ["2xl", "xl", "lg", "md", "sm"];

function handleError(event) {
  console.error("error", event);
}

function handleLoad(event) {}

function loadCompleted() {
  ref_img.value.classList.add("pic-img-loaded");
}

const sources = computed(() => {
  if (typeof props.src == "object") {
    const s = [];
    for (let i = 0; i < screensOrder.length; i++) {
      if (props.src.sources[screensOrder[i]]) {
        s.push({
          src: props.src.sources[screensOrder[i]],
          min: screens[screensOrder[i]].min,
        });
      }
    }
    return s;
  }

  return [];
});

onMounted(() => {
  if (ref_img.value.complete) {
    loadCompleted();
  }
});

return { __sfc: true,props, screens, ref_img, screensOrder, handleError, handleLoad, loadCompleted, sources }
}

}